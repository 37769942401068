import React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import LogisticsMapView from '~/client/src/shared/components/LogisticsMapView/LogisticsMapView'
import LogisticsMapViewSetUpStore from '~/client/src/shared/components/LogisticsMapView/LogisticsMapViewSetUp.store'
import MapViewsGallery from '~/client/src/shared/components/SitemapsGallery/MapViewsGallery'
import { LogisticsMapFilterType } from '~/client/src/shared/enums/LogisticsMapFilterType'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Announcement from '~/client/src/shared/models/Announcement'
import Delivery from '~/client/src/shared/models/Delivery'
import GlobeView from '~/client/src/shared/models/GlobeView'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import SitePermit from '~/client/src/shared/models/Permit'
import Sitemap from '~/client/src/shared/models/Sitemap'
import { SAVE_LOGISTICS_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import MobileFileInput from '../../../components/FileInput/MobileFileInput'
import MobileEventStore from '../../../stores/EventStore/MobileEvents.store'
import MobileInitialState from '../../../stores/MobileInitialState'
import MobileLogisticsStore from '../stores/MobileLogistics.store'
import MobileLogisticsMapFilterStore from '../stores/MobileLogisticsFilters/MobileLogisticsMapFilter.store'
import MobileLogisticsMapStore from '../stores/MobileLogisticsMap.store'
import CompactLogisticsFilter from './CompactLogsiticsFilter/CompactLogisticsFilter'
import LogisticsFilters from './LogisticsFilters/LogisticsFilters'

export interface IProps {
  logisticsStore: MobileLogisticsStore
  logisticsMapStore: MobileLogisticsMapStore
  showAnnouncement: (announcement: Announcement) => void
  openPermitViewForm: (permit: SitePermit) => void
  openDeliveryViewForm: (delivery: Delivery) => void

  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  logisticsMapFilterStore: MobileLogisticsMapFilterStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  tagsStore?: TagsStore
  state?: MobileInitialState
  globeViewsStore?: GlobeViewsStore
  eventsStore?: MobileEventStore

  isPermitOnly?: boolean
}
@inject(
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'tagsStore',
  'state',
  'companiesStore',
  'userProjectsStore',
  'projectMembersStore',
  'globeViewsStore',
  'eventsStore',
)
@observer
export default class MobileLogisticsMap extends React.Component<IProps> {
  public readonly store: LogisticsMapViewSetUpStore = null
  private textboxesCache: ICanvasImageCache = {}
  @observable public isLayersMenuShown: boolean = false

  public constructor(props: IProps) {
    super(props)

    this.store = new LogisticsMapViewSetUpStore(
      props.sitemapsStore,
      props.globeViewsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
      props.tagsStore,
      props.state,
      props.eventsStore,
    )

    const config = props.isPermitOnly
      ? props.globeViewsStore.formsMapIdsList
      : props.globeViewsStore.logisticMapIdsList

    const isGlobeMode = !!config?.[0]?.globeViewId
    if (isGlobeMode) {
      const globe = props.globeViewsStore.byId.get(config?.[0]?.globeViewId)
      this.store.globeViewControlStore.selectGlobe(globe)
    } else {
      const whiteboard = props.sitemapsStore.byId.get(config?.[0]?.sitemapId)
      this.store.selectSitemap(whiteboard)
      this.store.mapBoxViewerStore.setViewportFromAddress()
    }
  }

  public componentWillUnmount() {
    this.textboxesCache = {}
  }

  public render() {
    const { isPermitOnly, state } = this.props
    const { toggleFilters, isFiltersModalOpen } = this.props.logisticsMapStore

    return (
      <>
        <div className="map-filters body-header row full-width absolute z-index-100">
          <LogisticsFilters
            companiesStore={this.props.companiesStore}
            logisticsStore={this.props.logisticsStore}
            logisticsContentStore={this.props.logisticsMapStore}
            logisticsFilterStore={this.props.logisticsMapFilterStore}
            isPermitOnly={this.props.isPermitOnly}
            state={state}
            userProjectsStore={this.props.userProjectsStore}
            selectedOptionsCount={this.selectedOptionsCount}
            isMapFilters={true}
          />
        </div>
        <MapViewsGallery
          mapIds={
            isPermitOnly ? state.formsMapIdsList : state.logisticsMapIdsList
          }
          selectedGlobeViewId={
            this.store.globeViewControlStore.selectedGlobeViewId
          }
          selectedWhiteboardId={this.store.selectedSitemapId}
          isHideButtonDisplayed={false}
          renderMapViewComponent={this.renderMapViewComponent}
          eventName={SAVE_LOGISTICS_CONFIGURATIONS}
          selectGlobe={this.selectGlobeAndCloseModals}
          selectSitemap={this.selectSitemapAndCloseModals}
          toggleLayersMenu={this.toggleLayersMenu}
          shouldDisableSwap={true}
          shouldUseFullHeight={false}
          shouldDisableZoom={true}
          shouldIncreasedIcons={true}
          FileInputType={MobileFileInput}
          areArrowsLeftSided={true}
          isCompactMode={true}
          mapBoxViewerStore={this.store.mapBoxViewerStore}
          isPermitOnly={isPermitOnly}
          shouldShowCreateNewButton={false}
          hasFiltersBar={true}
        />
        <CompactLogisticsFilter
          store={this.props.logisticsMapFilterStore}
          onClose={toggleFilters}
          isShown={isFiltersModalOpen}
          selectedOptionsCount={this.selectedOptionsCount}
          companiesStore={this.props.companiesStore}
          projectMembersStore={this.props.projectMembersStore}
          tagsStore={this.props.tagsStore}
          isMapFilters={true}
        />
      </>
    )
  }

  private isApplicationHidden(
    filterType: LogisticsMapFilterType,
    id: string,
  ): boolean {
    const filter =
      this.props.logisticsMapFilterStore.filterStoresByTypeMap[filterType]
    return !filter || (!!filter.selectedOptions.size && !filter.selectedOptions.has(id))
  }

  private renderMapViewComponent = (): JSX.Element => {
    const {
      openPermitViewForm,
      showAnnouncement,
      openDeliveryViewForm,
      logisticsStore,
      isPermitOnly,
    } = this.props

    return (
      <div className="sitemap-container full-width full-height">
        <LogisticsMapView
          globe={this.store.globeViewControlStore.selectedGlobeView}
          sitemap={this.store.sitemap}
          store={this.store}
          logisticsStore={logisticsStore}
          textboxesCache={this.textboxesCache}
          shouldUseFullHeight={true}
          openPermit={openPermitViewForm}
          onPinClick={!isPermitOnly && showAnnouncement}
          openSiteDelivery={openDeliveryViewForm}
          openAnnouncement={showAnnouncement}
          shouldRenderSwipeableCards={true}
          mapBoxViewerStore={this.store.mapBoxViewerStore}
          isPermitOnly={isPermitOnly}
          isCompactMode={true}
          areActivitiesHidden={isPermitOnly || this.isApplicationHidden(
            LogisticsMapFilterType.App,
            LogisticItemApp.SCHEDULE,
          )}
          areAnnouncementsHidden={isPermitOnly ||this.isApplicationHidden(
            LogisticsMapFilterType.App,
            LogisticItemApp.ANNOUNCEMENT,
          )}
          arePermitsHidden={!isPermitOnly && this.isApplicationHidden(
            LogisticsMapFilterType.App,
            LogisticItemApp.FORM,
          )}
          areDeliveriesHidden={
            isPermitOnly ||
            this.isApplicationHidden(
              LogisticsMapFilterType.App,
              LogisticItemApp.DELIVERY,
            )
          }
          isLayersMenuShown={this.isLayersMenuShown}
          toggleLayersMenu={this.toggleLayersMenu}
          toggleAnnouncementsHiddenState={NOOP}
          toggleDeliveriesHiddenState={NOOP}
          togglePermitsHiddenState={NOOP}
          toggleMonitoringsHiddenState={NOOP}
        />
      </div>
    )
  }

  private toggleLayersMenu = () => {
    this.isLayersMenuShown = !this.isLayersMenuShown
  }

  private get selectedOptionsCount(): number {
    return Object.values(
      this.props.logisticsMapFilterStore.filterStoresByTypeMap,
    )
      .map(value => value.selectedOptions.size)
      .reduce((a, c) => a + c)
  }

  @action.bound
  private selectGlobeAndCloseModals(globe: GlobeView) {
    this.store.deselectSitemap()
    this.store.globeViewControlStore.selectGlobe(globe)
    this.store.mapBoxViewerStore.setViewportFromAddress()

    this.store.deselectAll()
  }

  @action.bound
  private selectSitemapAndCloseModals(sitemap: Sitemap) {
    this.store.globeViewControlStore.deselectGlobe()
    this.store.selectSitemap(sitemap)

    this.store.mapBoxViewerStore.setViewportFromAddress()

    this.store.deselectAll()
  }
}
